<template>
  <router-view></router-view>
</template>

<style lang="scss">
body {
  height: 100vh;
  margin: 0;
  background-color: #F4F5FA;
}
</style>
